import type { DataItemModel, ItemModel, RecordSourceFieldType } from 'o365.modules.DataObject.Types.ts';

/** Initial column options */
export type ColumnOptions = {
    /** Main field for this column from the DataObject */
    field?: string;
    /**
     * Optional unique id for this column. When not provided the field will be used by default.
     * This option should be used when you have multiple columns with the same field or this column 
     * is unbound and has no field
     */
    colId?: string;
    /**
     * Legacy option that behaves same as colId does. Should no longer be used 
     * @deprecated
     * @ignore
     */
    column?: string;
    /** Text shown in the header and column chooser labels  */
    headerName?: string;
    /** Text shown in the header tooltip */
    headerTitle?: string;
    /**
     * Width of the column in pixels, will be parsed into a number.
     * The default value is based on the data type of the column
     */
    width?: string | number;
    /**
     * Min width of the column in pixels, will be parsed into a number.
     * @default 50
     */
    minWidth?: string | number;
    /** Pinned position for this column. Default is undefined */
    pinned?: 'left'|'right';
    /**
     * Filter component for this column. Should be a component definition or a name of the component that is 
     * registered on the app
     * @default 'OFilter'
     */
    filter?: object | string;
    /** Optional field to use in filters instead of the main 'field' */
    filterField?: string;
    /** Optional field to use for sorting instead of the main 'field' */
    sortField?: string;
    /** Format pattern used by default editors and when rendering values */
    format?: string;
    /** Cell style. Can either be a static one for all cells or a per row function  */
    cellStyle?: VueStyleBinding | RowFunction<VueStyleBinding>;
    /** Cell tooltip function. Should be a function that takes in a row and returns a string */
    cellTitle?: RowFunction<string>;
    /**
     * Initially hide the column
     * @default false
     */
    hide?: boolean;
    /**
     * Boolean or a function that takes in a row and returns a boolean that will then be used to determine if this cell
     * should be editable or not
     * @default false
     */
    editable?: boolean | RowFunction<boolean>;
    /**
     * Enable sorting on this column
     * @default false
     */
    sortable?: boolean;
    /**
     * Don't render the drodpwon menu in the header for this column
     * @default false
     */
    disableMenu?: boolean;
    /**
     * Optional cell renderer component definition or registered component name.
     * When provided overrides #default slot
     */
    cellrenderer?: object | string;
    /**
     * Optional cell renderer component definition or registered component name.
     * When provided overrides #editor slot
     */
    celleditor?: object | string;
    /** Additional props object that will be provided to the cell renderer component */
    cellrendererparams?: object;  
    /** Additional props object that will be provided to the cell editor component */
    celleditorparams?: object;
    /**
     * Disable distinct filter for this column's field
     * @default false
     */
    disableDistinct?: boolean;
    /**
     * Disable distinct filter for this column's field
     * @default false
     */
    suppressNavigable?: boolean;
    /**
     * Disable drag selection for this column
     * @default false
     */
    suppressSelection?: boolean;
    /**
     * Enable bulk update for this column
     * @default false
     */
    bulkUpdate?: boolean;
    /** Function for setting classes on cells per row */
    classFn?: RowFunction<VueClassBinding>;
    /** Functionn for overriding bulk update functionlaity for this column */
    bulkUpdateApplyFunction?: (pBulkOptions: { bulkItem: ItemModel, bulkFields: string[], selectedRows: DataItemModel[] }) => void;
    /**
     * A recentage of the unused width that should be added to this column. 
     * If provided, should be a value between 1 = 100
     */
    flexwidth?: string | number;
    /**
     * Enter edit mode after a single click on this column
     * @default true
     */
    singleClickEdit?: boolean;
    /** Cell double click override function */
    dblclickHandler?: RowFunction<void>;
    /**
     * When enabled will force UTC mode for datepickers and formatted date renderers.
     * The values shown in grid will then be in UTC instead of local timezone.
     * @default false
     */
    utc?: boolean;
    /**
     * Function called when copying values that takes in a row and returns the value for copying.
     * Should be used on unbound columns or when custom renderers are used.
     */
    getCopyValue?: RowFunction<any>;
    /** Optional class to be used on the editor component */
    editorClass?: VueClassBinding;
    /**
     * Group by aggregate for this column used when GroupBy mode is enabled
     * Only legacy group by supported at the moment
     */
    groupAggregate?: RecordSourceFieldType['groupByAggregate'];
    /**
     * Custom group by aggregate function used on client side group by.
     * Only used in legacy group by.
     */
    groupAggregateFn?: RowsFunction<any>;
    /**
     * Aggregate to use for this column in the summary footer.
     * For the summary footer to be rednered at least one column has to have a summaryAggregate
     */
    summaryAggregate?: RecordSourceFieldType['aggregate'];
    /** Disable resizing for this column */
    disableResize?: boolean;
    /** Class for all cells in this column */
    class?: VueClassBinding;
    /**
     * #default slot
     */
    cellRenderSlot?: Slot<[]>
};

/** A function that takes in a DataItem and returns the defined T generic */
type RowFunction<T> = (pRow: DataItemModel) => T;
type RowsFunction<T> = (pRows: DataItemModel[]) => T;
type VueClassBinding = string | Record<string, boolean> | (string|Record<string, boolean>)[]
type VueStyleBinding = string | Record<string, any> | (string|Record<string, any>)[]
type Slot<T> = (...pArgs: T[]) => object;